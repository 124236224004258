<template>
  <div class="menber-dialog">
    <HeTong
      ref="hetong"
      v-if="showHt"
      :currentCode="identityObj.identityTypeCode == 2 ? 1 : 2"
      :currentItem="currentItem"
    />
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="560px"
    >
    <el-form ref="form" label-width="160px">
        <el-form-item label="农服商名称:">
          {{ currentItem.companyName }} <span v-if="currentItem.city">({{ currentItem.city }})</span>
        </el-form-item>
        <el-form-item label="经营场所地址:">
          {{ currentItem.address }}
        </el-form-item>
        <el-form-item label="联系人:">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="联系电话:"> {{ currentItem.phone }} </el-form-item>
        <el-form-item label="意向区域:">{{
          currentItem.intentionRegion
        }}</el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.operScope }}
        </el-form-item>
        <el-form-item label="可接管土地面积:">
          {{ currentItem.serviceArea ? currentItem.serviceArea : 0 }}
          亩</el-form-item
        >
        <el-form-item label="已接管土地面积:">
          {{ currentItem.serviceAreaOlde ? currentItem.serviceAreaOlde : 0 }}
          亩</el-form-item
        >
        <el-form-item label="经营场所照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.companyPhoto"
            :key="index"
            :preview-src-list="currentItem.companyPhoto"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="其他说明:">{{ currentItem.remark }}</el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="small" type="info" @click="back">关闭</el-button
        >
        <!-- <el-button size="small" type="primary" @click="exportPdf"
          >我要接管并导出合同</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import HeTong from "../../../quickLink/hetong.vue";
export default {
  data() {
    return {
      dialogVisible: true,
      showHt: false,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
    };
  },
  components: {
    HeTong,
  },
  mounted() {},
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {},
  methods: {
    init(obj) {
      // let pzzp = [];
      // if (obj.landCertificateUrl) {
      //   JSON.parse(obj.landCertificateUrl).forEach((item) => {
      //     pzzp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
      //   });
      // }
      // obj.pzzp = pzzp;
      // let xczp = [];
      // if (obj.livePhotos) {
      //   JSON.parse(obj.livePhotos).forEach((item) => {
      //     xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
      //   });
      // }
      // obj.xczp = xczp;
      this.currentItem = obj;
    },
    // 返回
    back() {
      this.$emit("closeDialog2", false);
    },
    // 关闭弹框
    handleClose() {
      this.$emit("closeDialog2", false);
    },
    // 导出合同
    exportPdf() {
      console.log(this.currentItem.orderState);
      if (this.currentItem.orderState == "01") {
        this.$message.info("此土地托管中，不可导出");
      } else if (this.currentItem.orderState == "02") {
        this.$message.info("此土地托管结束，不可导出");
        return;
      } else {
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.viewHt2(this.currentItem.orderId);
        }, 100);
      }
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
}
.btns {
  // display: flex;
  margin-top: 20px;
  padding: 0 120px;
  box-sizing: border-box;
  // justify-content: space-between;
  text-align: center;
}
</style>
